:root {

--mobile-min: 0px;
--mobile-max: 480px;

--tablet-min: 481px;
--tablet-max: 768px;

--desktop-min: 769px;
--desktop-max: 1024px;

--large-screen-min: 1025px;
--large-screen-max: 1200px;



--primary-light: var(--provincial-pink);
--text-dark: var(--space-cadet);

--fs-med: 1.6rem;

  /* CSS HEX */
--dark-sky-blue: #7EC1DEff;
--space-cadet: #36304Bff;
--english-violet: #5C4E6Cff;
--dark-purple: #312A40ff;
--magenta-crayola: #E9599Eff;

/* Nebula */
  --spun-pearl: #a09dab;
  --provincial-pink: #fcf6f3;
  --chardonnay: #f8c275;
  --turquoise: #3fdffe;
  --light-crimson: #f36f8c;
  --mauve: #e7adfb;
  --royal-purple: #7855a2;
  --ebony-clay: #27273a;
  --black-pearl: #1f2330;
  --martinique: #3a3255;
  --vulcan: #0f1420;
  
  /* Greys */
  --silver-metallic: #b4b2bdff;
  --silver-metallic-2: #a9a7b4ff;
  --manatee: #9f9cabff;
  --manatee-2: #9491a1ff;
  --roman-silver: #898698ff;

  /* Turquoise */
  --blizzard-blue: #9aeefeff;
  --sky-blue-crayola: #71e7feff;
  --sky-blue-crayola-2: #3fdffeff;
  --vivid-sky-blue: #20d9feff;
  --vivid-sky-blue-2: #0bd5feff;

  /* Crimson */
  --orchid-pink: #fac6d2ff;
  --mauvelous: #f7a1b3ff;
  --ultra-red: #f36f8cff;
  --brink-pink: #f15577ff;
  --paradise-pink: #ee2f59ff;

  /* Purples */
  --wisteria: #b5a1ceff;
  --purple-mountain-majesty: #a087c0ff;
  --royal-purple: #7855a2ff;
  --cyber-grape: #4f386bff;
  --russian-violet: #3b2a50ff;

  /* Blues */ 
  --dark-blue-gray: #5f6c95ff;
  --independence: #505a7cff;
  --independence-2: #404864ff;
  --space-cadet: #30364bff;
  --raisin-black: #1f2330ff;
}

/* Media Queries */
@media screen and (min-width: var(--mobile-max)) {
  .mobile-visible {
    display: none;
  }
  .desktop-visible {
    display: block;
  }
}
@media screen and (min-width: var(--tablet-min)) {
  .project-info > .ui.segment, .projects .ui.tabular.menu .active.item {
    background: var(--raisin-black);
  }
}
@media screen and (max-width: var(--mobile-max)) {
  .project-info > .ui.segment, .projects .ui.tabular.menu .active.item {
      background: transparent;
  }
}




h1 {
  color: var(--provincial-pink);
}
h2, .projects h2.ui.header {
  color: var(--magenta-crayola);
}
h3 {
  font-size: 3rem;
  color: var(--orchid-pink)
}

h4 {
  color: var(--royal-purple);
}

.about-me > h1 {
  color: #A087C0;
}
.ui.segment.about-me {
  padding: 0rem;
  margin: 8vw;
  background: #252839;
}
.segment .ui.horizontal.list > .item,
.ui.segment, .ui.segments .segment p,
.skill-list > .item:first-child,
.skill-list > .item:last-child {
  font-size: 1.2rem;
}
ul.ui.horizontal.bulleted.list li::before, 
.ui.horizontal.bulleted.list > .item::before {
  color: #A087C0;
}
.profile-skills {
  background-color: transparent;
}
.profile-skills .segments .segment {
  background-color: #3a325577;
}
.profile-skills h3.ui.header {
  margin-bottom: 0;
}
.profile-skills .list.skill-list {
  margin: 0.25rem 0 0 0rem;
  text-align: center;
}
.profile-skills .skill-list > .item {
  display: inline-block;
  list-style: none;
  line-height: 1.3;
}
.about-text {
  margin: 3rem 0;
}
.about-me > div {
  background-color: #0f1420;
  padding: 0;
}
.segment .about-text p {
  font-size: 1.1em;
  font-weight: 600;
  word-break: unset;
  hyphens: none;
}
div.about-text {
  margin: 3rem 0;
}
.segment.about-me strong {
  color: var(--mauve);
}

body {
  font-family: "Lato", "Helvetica Neue", Arial, Helvetica, sans-serif;
  font-size: 1.4rem;
}
.container {
  align-items: center;
  display: flex;
  justify-content: center;
}
.name-title {
  margin: 0;
}

.name-box {
  margin: 0 auto;
  /* font-family: 'Montserrat', sans-serif; */
  font-style: normal;
  font-weight: 500;
  font-size: 50px;
  color: var(--silver-metallic);
}
.title-box {
  /* font-family: 'Open Sans', sans-serf; */
  color: var(--royal-purple);
}
.segment.about-me {
  background-color: var(--black-pearl);
}

.projects.ui.segment {
  border: none;
  box-shadow: none;
  background-color: #0f1420;
}
.gallery.ui.segment {
  background-color: var(--ebony-clay);
}

.projects .ui.header, .gallery .ui.header {
  padding: 2rem 0em 1rem;
}
.projects.ui.segment, .gallery.ui.segment {
  margin: 1vh 0 1vh;
  padding: 1vh 0 6vh;
}



.projects .ui.attached.segment, .projects .ui.tabular.menu, .projects .ui.tabular.menu .active.item {
  border-color: var(--english-violet);
}

.ui.segment[class*="bottom attached"]{
  border-radius: 7px !important;
  border-color: var(--english-violet) !important;
}

.projects .ui.segment, .projects .ui.tabular.menu .active.item {
  background: transparent;
  color: var(--silver-metallic);
  border-radius: 5px;
}



.projects .ui.secondary.inverted.menu .active.item, 
.ui.inverted.menu .item::before {
  background: rgba(255,255,255,.08);
}

.ui.stackable.menu .item::before {
  position: absolute;
  content: '';
  top: auto;
  bottom: 0;
  left: 0;
  width: 100%;
  height: 1px;
  background: rgba(34,36,38,.1);
}
.ui.secondary.menu .item::before {
  display: none !important;
}
.ui.menu .item::before {
  position: absolute;
  content: '';
  top: 0;
  right: 0;
  height: 100%;
  width: 1px;
  background: rgba(34,36,38,.1);
}
::selection {
  background-color: #cce2ff;
  color: rgba(0,0,0,.87);
}

/* .projects .ui.secondary.inverted.menu a.item {
  color: 
} */
/* .ui.segment.projects {
  margin: 0 0 1em;
  padding: 1em 1em 0em;
}
.projects .ui.header {
  padding: 1em 0em 3em
  margin 3em;
} */
.projects .ui.segment.project-grid {
  background: rgb(28,28,29);
  background: linear-gradient(156deg, rgba(28,28,29,1) 0%, rgba(44,44,46,1) 100%);
}
.projects .project-menu {
  margin: 0;
  width: 100%;
}
.projects .project-menu {
  display: flex;
  flex-wrap: wrap;
  justify-content:space-around;
}

/* This is to fix the space between items in the project menu */
/* may need to be adjusted with max-width: calc() something later */
/* the semantic ui max width is max-width: calc(100% - (-1px * 2)); */
.ui.attached.tabular.menu.project-menu {
  width: 100%;
  margin: 0 auto;
  max-width: fit-content;
}
.projects .ui.secondary.pointing.menu .item {
  margin: 0em 1em;
  padding: 1em 0.5em;
  background-color: transparent;
  font-size: 1.3rem;
  font-weight: 500;
}

.projects .ui.secondary.pointing.menu .item.active, .projects .ui.secondary.pointing.menu .item.active:hover  {
  border-color: #a291fb !important;
  color: #a291fb !important;
  background-color: transparent;
  font-weight: 700;
}
.projects .ui.secondary.pointing.menu a.item:hover {
  color: rgba(255,255,255,0.7) !important;
}
.projects .ui.secondary.pointing.menu a.item {
  color: rgba(255,255,255) !important;
}

.projects .project-row {
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
  margin: 0 auto;
  max-width: 1200px;
  padding: 2em 0 0;
}
.project-row .project-image {
  margin: 1em auto 2em; 
  text-align: center;
  order: 1;
}
.projects .ui.secondary.pointing.menu::after {
  content: none !important;
}
.project-row .project-info {
  margin: 0px auto; 
  padding: 0em 1em 1em 0;
  order: 2;
}
@media screen and (max-width: 768px) {
  .project-row .project-image {
    order: 2;
  }
  .project-row .project-info {
    order: 1;
  }
}

/* .projects .ui.secondary.inverted.pointing.menu .active.item {
  color: #a291fb !important;
  border-color: #a291fb !important;
  background-color: transparent;
} */
/* .projects .ui.secondary.inverted.menu .active.item {
  color: #a291fb !important;
} */

.projects ul li, .projects p {
  color: var(--provincial-pink);
  font-size: 1.3em;
}

.projects .description {
  font-size: var(--fs-med);
}

.projects .tag-container {
  display: flex;
  flex-wrap: wrap;
  margin-bottom: 1rem;
}

.projects .tag-container .tags { 
  font-size: 1.2em;
  font-weight: 700;
  background-color: var(--primary-light);
  color: var(--text-dark);
  border-radius: 5px;
  padding: 0.25rem;
  margin: 0 1rem 1rem 0;
}

/* Design Examples */


.gallery .card-grid {
  overflow: auto;
  scrollbar-color: var(--roman-silver) transparent;
  scrollbar-width: thin;
  display: flex;
  flex-direction: row;
  flex-wrap: nowrap;
  align-items: center;
  justify-items: space-around;
  padding: 1rem 0;
}
/* Inner card grid */

.ui.tertiary.inverted.segment.card-grid {
  background-color: var(--roman-silver);
  padding: 1rem 0;
} 

.card-grid .ui.card.card {
  box-shadow: 2px 2px 5px 0px grey;
  padding: 0.15rem;
  margin: 1rem 0.5rem;
  background-color: var(--independence-2);
}
.gallery .ui.menu {
  font-size: 1.3rem;
  font-weight: 700;
}
.gallery .gallery-nav {
  display: flex; 
  justify-content:center; 
  margin: auto; 
  align-items:baseline; 
  border-bottom:0px; 
}
.gallery .ui.secondary.inverted.pointing.menu .active.item {
  color: #54c8ff !important;
  border-color: #54c8ff;
}

.description .project-description > span {
  color: black;
  display: block;
  font-size: 1rem;
  padding: 0.5rem 0rem;
  text-align:justify;
}
.description .project-description > span:first-child {
  font-weight: 700;
  display: block;
}

.gallery .ui.secondary.pointing.menu {
  border: 0px;
}

.gallery .ui.segments {
  border: 1px solid var(--roman-silver);
  background-color:#0f1420;
  box-shadow: 4px 11px 16px -12px rgba(69, 69, 69, 0.75);
}

.gallery .menu {
  border: 0px;
  font-size: 1.1rem;
  display: flex;
  justify-content: center;
  padding: 1rem 0 2rem;
}

.gallery .menu .item {
  border-bottom: 0px;
}

@media screen and (max-width: 768px) {
  .gallery .card-grid {
    margin: 0;
    padding: 0;
    display: flex;
    align-content: stretch;
    justify-content: center;
    flex-direction: row;
    flex-wrap: wrap;
  }
  
  .card-grid .list {
    margin: 0;
    padding: 0;
    width: fit-content;
    align-self: center;
  }
  .card-grid .ui.horizontal.list {
    display: inline-block;
    font-size: 0;
    width: 50%;
    padding: 0 0.5rem;
  }
}




/*


.navbar-fixed nav {
  background-color: var(--raisin-black);
  border-bottom: solid 1px var(--vulcan);
  box-shadow: none;
}

.sidebar {
  background-color: var(--independence-2);
  justify-content: space-around;
  align-content:flex-start;
  border-right: solid 1px var(--vulcan);
}

.intro {
  display: flex;
  flex-flow: column wrap;
}

.main {
  background-color: var(--space-cadet);
}

.about-me {
  background-color: var(--spun-pearl);
  width: 100%;
  display:flex;
  align-items: center;
  justify-content: center;
}
.about-me p {
  text-align: justify;
}

.intro .btn-large {
  background-color: var(--ultra-red);
}
.intro .btn-large:hover {
  background-color: var(--brink-pink);
}

nav ul a,
nav .brand-logo {
  color: #444;
}

p {
  line-height: 2rem;
}

.sidenav-trigger {
  color: #26a69a;
}

.parallax-container {
  min-height: 380px;
  line-height: 0;
  height: auto;
  color: rgba(255,255,255,.9);
}
  .parallax-container .section {
    width: 100%;
  }

@media only screen and (max-width : 992px) {
  .parallax-container .section {
    position: absolute;
    top: 40%;
  }
  #index-banner .section {
    top: 10%;
  }
}

@media only screen and (max-width : 600px) {
  #index-banner .section {
    top: 0;
  }
}

.icon-block {
  padding: 0 15px;
}
.icon-block .material-icons {
  font-size: inherit;
}

footer.page-footer {
  margin: 0;
}

.body-content {
  display:flex;
} */
